import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Menu,
  Typography,
} from "@mui/material";
import React from "react";
import { flexCenter } from "../../theme/commonThemes";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  menuContainer: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "var( --color-stroke)",
      },
      "&::-webkit-scrollbar": {
        display: "block",
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      " &::-webkit-scrollbar-thumb": {
        backgroundColor: "#d9e0e7",
        //background: "transparent",
        borderRight: "none",
        borderLeft: "none",
      },

      "&::-webkit-scrollbar-track-piece:end": {
        background: "transparent",
        marginBottom: "10px",
      },

      "&::-webkit-scrollbar-track-piece:start": {
        background: "transparent",
        marginTop: "10px",
      },
    },
  },
}));
export default function SearchedLocationMenu({
  searchedPlaces,
  isLoading,
  anchorEl,
  handleClose,
  handlePopularLocationClick,
  popularLocations,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.menuContainer}>
      {(searchedPlaces || isLoading || popularLocations) && (
        <Menu
          anchorEl={anchorEl}
          className={classes.menuContainer}
          disableAutoFocus={true}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          // getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          PaperProps={{
            style: {
              marginTop: "24px",
              marginBottom: "24px",
              borderRadius: "8px",
              boxShadow: "0px 6px 72px rgba(63, 0, 30, 0.16)",
              minWidth: "313px",
              maxHeight: "300px",
              marginLeft: "-55px",
            },
          }}
        >
          {searchedPlaces &&
            searchedPlaces?.length > 0 &&
            searchedPlaces?.map((predictedPlace, index) => {
              return (
                <List key={index} sx={{ p: 0,cursor:'pointer' }}>
                  <ListItem
                    onClick={() => {
                      handlePopularLocationClick(
                        predictedPlace?.name,
                        predictedPlace?.lat,
                        predictedPlace?.lng
                      );
                    }}
                  >
                    <img src="/mobileImages/location.svg" alt="" />
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "var(--text-primary)",
                        fontWeight: 500,
                        ml: "12px",
                        textTransform: "uppercase",
                      }}
                      variant="subtitle1"
                    >
                      {predictedPlace?.name}
                    </Typography>
                  </ListItem>
                  <Divider sx={{ my: 1, mx: 2 }} />
                </List>
              );
            })}
          {isLoading && (
            <Box
              sx={{
                ...flexCenter,
                p: 5,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && searchedPlaces && searchedPlaces?.length < 1 && (
            <Box
              sx={{
                ...flexCenter,
                p: 5,
              }}
            >
              <Typography variant="subtitle1">No Data Found</Typography>
            </Box>
          )}

          {!isLoading &&
            !searchedPlaces &&
            popularLocations &&
            popularLocations?.length > 0 &&
            popularLocations?.map((popularPlace, index) => {
              return (
                <List key={index} sx={{ p: 0 }}>
                  <ListItem
                    onClick={() => {
                      handlePopularLocationClick(
                        popularPlace?.name,
                        popularPlace?.lat,
                        popularPlace?.lng
                      );
                    }}
                  >
                    <img src="/mobileImages/location.svg" alt="" />
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "var(--text-primary)",
                        fontWeight: 500,
                        ml: "12px",
                        textTransform: "uppercase",
                      }}
                      variant="subtitle1"
                    >
                      {popularPlace?.name}
                    </Typography>
                  </ListItem>
                  <Divider sx={{ my: 1, mx: 2 }} />
                </List>
              );
            })}
        </Menu>
      )}
    </Box>
  );
}
