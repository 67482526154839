import { isSameMonth, isSameYear } from "date-fns";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

const timeFormatting = (timeString) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute;
};

const dateDifference = (a, b) => {
  const date1 = new Date(a);
  const date2 = new Date(b);
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const formatDate = (date, template = "MMM dd, yyyy") => {
  return format(parseISO(new Date(date).toISOString()), template);
};

const daysOfTheYear = (year) => {
  const date = new Date(year);
  const totalDays =
    date.getFullYear() * 365 + date.getMonth() * 30 + date.getDate();
  return totalDays;
};

const formatYearDate = (date, template = "yyyy-MM-dd") => {
  return format(parseISO(new Date(date).toISOString()), template);
};

const formatTime = (date, template = "hh:mm a") => {
  return format(parseISO(new Date(date).toISOString()), template);
};

const formateMonthDate = (date, template = "MMM dd") => {
  return format(parseISO(new Date(date).toISOString()), template);
};

const formateDateMonth = (date, template = "dd MMM") => {
  return format(parseISO(new Date(date).toISOString()), template);
};
const formatMonthDateYear = (date, template = "dd MMM, yy") => {
  return format(parseISO(new Date(date).toISOString()), template);
};

const formatMonthDateFullYear = (date, template = "dd MMM, yyyy") => {
  return format(parseISO(new Date(date).toISOString()), template);
};

const formatDateRange = (startDate, endDate) => {
  const start = format(
    parseISO(new Date(startDate).toISOString()),
    "dd MMM yyyy"
  );
  const end = format(parseISO(new Date(endDate).toISOString()), "dd MMM yyyy");
  const sameMonth = isSameMonth(startDate, endDate);
  const sameYear = isSameYear(startDate, endDate);
  if (sameMonth && sameYear) {
    return `${start.substring(0, 2)} - ${end.substring(0, 2)} ${end.substring(
      3
    )}`;
  } else {
    return `${format(
      parseISO(new Date(startDate).toISOString()),
      "dd MMM yy"
    )} - ${format(parseISO(new Date(endDate).toISOString()), "dd MMM yy")}`;
  }
};

const dateSubtract = (checkingDate, day) => {
  const date = new Date(checkingDate);
  date.setDate(date.getDate() - day);
  return formatDate(date);
};

const dateAdd = (givenDate, day) => {
  const date = new Date(givenDate);
  date.setDate(date.getDate() + day);
  return formatYearDate(date);
};

const bookingDateNullChecker = (data) => {
  let updatedDate;
  if (data.status_updated_at !== null) {
    updatedDate = data.status_updated_at;
    return updatedDate;
  } else {
    updatedDate = data.created_at;
    return updatedDate;
  }
};

const getDiscount = (data) => {
  const discount = Math.ceil(data.more_nights_discount + data.discount);
  return discount;
};

export {
  timeFormatting,
  dateDifference,
  formatDate,
  daysOfTheYear,
  formatYearDate,
  formatTime,
  formateMonthDate,
  formateDateMonth,
  formatMonthDateYear,
  formatMonthDateFullYear,
  formatDateRange,
  dateSubtract,
  dateAdd,
  bookingDateNullChecker,
  getDiscount,
};
