import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { flexBetween } from "../../theme/commonThemes";
const useStyles = makeStyles((theme) => ({
  buttonGroupRoot: {
    "& .MuiButtonGroup-root": {
      height: "40px",
      width: "115px",
      "& .MuiButtonBase-root": {
        border: "none",
        width: "40px",
        borderRadius: "8px !important",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#1A1819",
      },
      "& .Mui-disabled": {
        backgroundColor: "#F5F5F5",
      },
    },
  },
  minusBtn: {
    backgroundColor: "#F5F5F5 !important",
  },
  addBtn: {
    backgroundColor: "#1A1819 !important",
  },
}));
const CustomCounter = ({
  handleOption,
  optionsName,
  name,
  label,
  details,
  padding,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box
        className={classes.root}
        sx={{
          ...flexBetween,
          padding: padding ? padding : "24px 0px",
        }}
      >
        <Box>
          <Typography variant="subtitle1">{label}</Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "var(--text-secondary)" }}
          >
            {details}
          </Typography>
        </Box>
        <Box className={classes.buttonGroupRoot}>
          <ButtonGroup>
            <Button
              disableElevation
              variant="contained"
              className={classes.minusBtn}
              onClick={() => handleOption(name, "d")}
              disabled={optionsName <= 0}
            >
              <img src="/mobileImages/minus.svg" alt="" />
            </Button>
            <Button disableElevation>{optionsName}</Button>
            <Button
              disableElevation
              variant="contained"
              className={classes.addBtn}
              onClick={() => handleOption(name, "i")}
            >
              <img src="/mobileImages/add.svg" alt="" />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export default CustomCounter;
