import { Box, Button, Menu, Typography } from "@mui/material";
import React, { useState } from "react";
import "react-nice-dates/build/style.css";
import { makeStyles } from "@mui/styles";
import { flexBetweenCenter, justifyCenter } from "../../theme/commonThemes";
import {
  DateRangePickerCalendar,
  START_DATE,
  END_DATE,
} from "react-nice-dates";
import { format, parseJSON } from "date-fns";
import { enGB } from "date-fns/locale";
import { dateDifference, formatMonthDateFullYear } from "../utilities/timeFunctionsModel";

const useStyles = makeStyles((theme) => ({
  dateRoot: {
    "& .nice-dates-day_date": {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .nice-dates-navigation_current": {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "24px",
      color: "var(--text-primary)",
    },
    "& .nice-dates-week-header": {
      boxShadow: "none",
    },
    "& .nice-dates-week-header_day": {
      fontSize: 0,
    },
    "& .nice-dates-week-header_day:first-letter": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "var(--text-secondary)",
    },

    "& .nice-dates-day:before": {
      backgroundColor: "var(--primary-color)",
    },
    "& .nice-dates-day": {
      "&:after": {
        border: "1px solid var(--primary-color)",
      },
    },
    "& .nice-dates-day.-selected.-selected-middle:before": {
      backgroundColor: "#FFE4F1",
    },
    "& .nice-dates-day.-selected.-selected-middle": {
      "& .nice-dates-day_date": {
        color: "var(--text-primary) !important",
      },
    },
  },
  dateTextRoot: {
    width: "126.67px",
    height: "62px",
    transition: "border-bottom 0.5s ease",
  },
}));
export default function DateRangePickerMenu({
  anchorDateOpen,
  handleDateClose,
  from,
  to,
  setFrom,
  setTo,
  width,
}) {
  const classes = useStyles();
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };
  return (
    <Menu
      anchorEl={anchorDateOpen}
      keepMounted
      disableAutoFocus={true}
      onClose={handleDateClose}
      open={Boolean(anchorDateOpen)}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          marginTop: "24px",
          borderRadius: "8px",
          boxShadow: "0px 6px 72px rgba(63, 0, 30, 0.16)",
          padding: "32px 37px",
        },
      }}
    >
      <Box>
        <Box
          sx={{ width: width ? width : "450px" }}
          className={classes.dateRoot}
        >
          <Box sx={justifyCenter}>
            <Box
              sx={{
                ...flexBetweenCenter,
                marginBottom: "8px",
                textAlign: "center",
                width: "380px",
              }}
            >
              <Box
                className={classes.dateTextRoot}
                sx={{
                  borderBottom: from
                    ? "1px solid #E81478"
                    : "1px solid var(--border-color)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "var(--text-secondary)", marginBottom: "4px" }}
                >
                  Check In
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {from ? formatMonthDateFullYear(from) : "Select Date"}
                </Typography>
              </Box>
              <Box
                className={classes.dateTextRoot}
                sx={{
                  ...justifyCenter,
                  borderBottom: "1px solid var(--border-color)",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #F5F5F5",
                    height: "46px",
                    width: "86px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "var(--primary-color)" }}
                  >
                    {from && to ? dateDifference(from, to) : 0}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "var(--primary-color)" }}
                  >
                    Days
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classes.dateTextRoot}
                sx={{
                  borderBottom: to
                    ? "1px solid #E81478"
                    : "1px solid var(--border-color)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "var(--text-secondary)", marginBottom: "4px" }}
                >
                  Check Out
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {to ? formatMonthDateFullYear(to) : "Select Date"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <DateRangePickerCalendar
            startDate={from}
            endDate={to}
            minimumDate={new Date()}
            focus={focus}
            onStartDateChange={setFrom}
            onEndDateChange={setTo}
            onFocusChange={handleFocusChange}
            locale={enGB}
          />
          <Box sx={{ paddingTop: "24px" }}>
            <Button
              fullWidth
              sx={{
                fontWeight: 600,
                fontSize: " 14px",
                height: "56px",
                borderRadius: "4px",
                color: "var(--background-white)",
              }}
              variant="contained"
              disableElevation
              onClick={handleDateClose}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </Menu>
  );
}
