import { Badge, Box, Paper, Typography } from "@mui/material";
import React from "react";
import { flexCenter, flexBetweenCenter } from "../../../../theme/commonThemes";
import Link from "next/link";
import { makeStyles } from "@mui/styles";
import { useRouter } from "next/router";
import { IoNotificationsOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getAuth } from "../../../api/auth";
import notificationModule from "../../../modules/notificationModule";
import { useQuery } from "react-query";
const useStyles = makeStyles((theme) => ({
  root: {
    "& img": {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12px",
      textAlign: "center",
      marginTop: "10px",
      color: "var(--text-secondary)",
    },
  },
  activeMenu: {
    "& img, svg": {
      filter:
        "brightness(0) saturate(100%) invert(22%) sepia(97%) saturate(4665%) hue-rotate(320deg) brightness(92%) contrast(98%)",
    },
    "& .MuiTypography-root": {
      color: "var(--primary-color)",
    },
  },
}));
export default function MobileFooter() {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const { user_profile } = getAuth();
  const fetchInfiniteNotifications = async () => {
    const res = await dispatch(
      notificationModule.getAll({
        limit: 20,
        page: 1,
      })
    );
    return res.data;
  };
  const { data = null } = user_profile?.id ? useQuery(
    ["notificationCountPhoneLists", user_profile?.id],
    () => fetchInfiniteNotifications()
  ) : {};

  let notificationCount = 0;
  if (data && data.data) {
    notificationCount = data?.data.filter((x, i) => {
      return x.new === true;
    }).length;
  }
  return (
    <>
      <Paper
        sx={{
          position: "fixed",
          height: "82px",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "0",
          boxShadow: "0px -4px 35px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Box
          sx={{ height: "100%", padding: "7px 24px", ...flexBetweenCenter }}
          className={classes.root}
        >
          <Box
            className={
              router.pathname === "/" || router.pathname === "/locationSearch"
                ? classes.activeMenu
                : null
            }
          >
            <Link href="/" as="/" passHref shallow>
              <img src="/images/icons/search-normal.svg" />
              <Typography>Explore</Typography>
            </Link>
          </Box>
          <Box
            className={
              router.pathname === "/booking" ? classes.activeMenu : null
            }
          >
            <Link href="/booking" as="/booking" passHref shallow>
              <img src="/mobileImages/note.svg" />
              <Typography>Booking</Typography>
            </Link>
          </Box>
          <Box
            className={
              router.pathname === "/chat/[id]" ? classes.activeMenu : null
            }
          >
            <Link href="/chat/*" as="/chat/*" passHref shallow>
              <img src="/mobileImages/messages.svg" />
              <Typography>Inbox</Typography>
            </Link>
          </Box>
          <Box
            className={
              router.pathname === "/notifications" ? classes.activeMenu : null
            }
          >
            <Link href="/notifications" as="/notifications" passHref shallow>
              <Box sx={{ ...flexCenter }}>
                {notificationCount && notificationCount > 0 ? (
                  <Badge
                    color="primary"
                    badgeContent={notificationCount}
                    max={99}
                  >
                    <IoNotificationsOutline
                      size={22}
                      width="24px"
                      height="24px"
                      color="var(--text-secondary)"
                    />
                  </Badge>
                ) : (
                  <IoNotificationsOutline
                    size={22}
                    width="24px"
                    height="24px"
                    color="var(--text-secondary)"
                  />
                )}
              </Box>
              <Typography>Notification</Typography>
            </Link>
          </Box>
          <Box
            className={
              router.pathname === "/profile" ? classes.activeMenu : null
            }
          >
            <Link href="/profile" as="/profile" passHref shallow>
              <Box sx={{ ...flexCenter }}>
                <img src="/mobileImages/profile.svg" />
              </Box>
              <Typography>Profile</Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
