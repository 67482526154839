import { Box, Button, Menu } from "@mui/material";
import React from "react";
import CustomCounter from "./CustomCounter";

export default function SelectGuestMenu({
  anchorGuestOpen,
  handleGuestClose,
  handleOption,
  options,
  handleCloseOption,
}) {
  return (
    <Menu
      anchorEl={anchorGuestOpen}
      keepMounted
      disableAutoFocus={true}
      onClose={handleGuestClose}
      open={Boolean(anchorGuestOpen)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          marginTop: "24px",
          borderRadius: "8px",
          boxShadow: "0px 6px 72px rgba(63, 0, 30, 0.16)",
          padding: "0px 16px",
          marginLeft: "8px",
          width: "390px",
        },
      }}
    >
      <Box sx={{ borderBottom: "1px solid #F5F5F5" }}>
        <CustomCounter
          name="adult"
          label="Adult"
          handleOption={handleOption}
          optionsName={options.adult}
          details="Ages 13 or above"
          padding="16px 0px"
        />
      </Box>

      <Box sx={{ borderBottom: "1px solid #F5F5F5" }}>
        <CustomCounter
          name="children"
          label="Children"
          handleOption={handleOption}
          optionsName={options.children}
          details="Ages 2-12"
          padding="16px 0px"
        />
      </Box>

      <Box>
        <CustomCounter
          name="infants"
          label="Infants"
          handleOption={handleOption}
          optionsName={options.infants}
          details="Under 2"
          padding="16px 0px"
        />
      </Box>
      <Box sx={{ py: "24px" }}>
        <Button
          fullWidth
          sx={{
            fontWeight: 600,
            fontSize: " 14px",
            height: "56px",
            borderRadius: "4px",
            color: "var(--background-white)",
          }}
          variant="contained"
          disableElevation
          onClick={handleGuestClose}
        >
          Done
        </Button>
      </Box>
    </Menu>
  );
}
