import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { flexBetweenCenter } from "../../../theme/commonThemes";
import { makeStyles } from "@mui/styles";
import MaplocationRepo from "../../repositories/MaplocationRepo";
import SearchedLocationMenu from "../SearchedLocationMenu";
import SelectGuestMenu from "../SelectGuestMenu";
import { FormProvider, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import DateRangePickerMenu from "../DateRangePickerMenu";
import { useDispatch } from "react-redux";
import mapLocationModule from "../../modules/mapLocationModule";
import {
  dateAdd,
  formatDateRange,
  formatYearDate,
} from "../../utilities/timeFunctionsModel";
const useStyles = makeStyles((theme) => ({
  paperRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiPaper-root": {
      position: "absolute",
      overflow: "hidden",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      maxWidth: "992px",
      height: "80px",
      borderRadius: "8px",
      backgroundColor: "var(--background-white)",
      boxShadow: "0px 6px 72px rgba(63, 0, 30, 0.07)",
    },
  },

  dateRoot: {
    "& .nice-dates-day_date": {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .nice-dates-navigation_current": {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "24px",
      color: "var(--text-primary)",
    },
    "& .nice-dates-week-header": {
      boxShadow: "none",
    },
    "& .nice-dates-week-header_day": {
      fontSize: 0,
    },
    "& .nice-dates-week-header_day:first-letter": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "var(--text-secondary)",
    },

    "& .nice-dates-day:before": {
      backgroundColor: "var(--primary-color)",
    },
    "& .nice-dates-day": {
      "&:after": {
        border: "1px solid var(--primary-color)",
      },
    },
    "& .nice-dates-day.-selected.-selected-middle:before": {
      backgroundColor: "#FFE4F1",
    },
    "& .nice-dates-day.-selected.-selected-middle": {
      "& .nice-dates-day_date": {
        color: "var(--text-primary) !important",
      },
    },
  },
  dateTextRoot: {
    width: "126.67px",
    height: "62px",
    transition: "border-bottom 0.5s ease",
  },
  boxRoot: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    padding: "16px 0px",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid var(--primary-color)",
    },

    ".MuiTypography-root": {
      wordBreak: "break-all",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
}));

export default function LocationSearchDesk({
  placeName,
  fromDate,
  toDate,
  guest,
  child,
  infant,
  locationPrev,
  width,
  height,
  buttonText,
}) {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsloading] = useState(false);
  const [searchCity, setSearchCity] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(
    placeName ? placeName : null
  );
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDateOpen, setAnchorDateOpen] = useState(null);
  const [anchorGuestOpen, setAnchorGuestOpen] = useState(null);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    setError,
    reset,
    formState: { isSubmitting, errors },
  } = useForm();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDateOpen = (e) => {
    setAnchorDateOpen(e.currentTarget);
  };
  const handleDateClose = () => {
    setAnchorDateOpen(null);
  };
  const handleGuestOpen = (e) => {
    setAnchorGuestOpen(e.currentTarget);
  };
  const handleGuestClose = () => {
    setAnchorGuestOpen(null);
  };

  let searchCityPrev = "";
  const [searchedPlaces, setSearchedPlaces] = useState(null);
  const [popularLocations, setPopularLocations] = useState(null);

  let firstPlace;
  const FetchSearchResults = async () => {
    const res = await dispatch(mapLocationModule.getAll({ q: searchCity }));
    if (res.data) {
      setSearchedPlaces(res.data);
      setIsloading(false);
    }
  };

  const FetchPopularLocations = async () => {
    setIsloading(true);
    const response = await MaplocationRepo.getPopularLocation();
    if (response.status === 200 || response.success === true) {
      if (response.data) {
        setPopularLocations(response.data);
        setIsloading(false);
      }
    }
  };

  useEffect(() => {
    if (searchCity === "") {
      FetchPopularLocations();
      setSearchedPlaces(null);
    }
    if (searchCity.length > 0 && searchCity.length < 2) {
      if (searchCity !== searchCityPrev) {
        setIsloading(true);
        searchCityPrev = searchCity;
        FetchSearchResults();
      } else {
        return;
      }
    }
    if (searchCity.length > 1) {
      let interval = setInterval(async () => {
        if (searchCity !== searchCityPrev) {
          setIsloading(true);
          searchCityPrev = searchCity;
          FetchSearchResults();
        } else {
          return;
        }
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    } else {
      //handleClose();
      return;
    }
  }, [searchCity]);

  const handleOnChange = (e) => {
    e.preventDefault();
    setSelectedPlace(null);
    setSearchCity(e.target.value);
    setAnchorEl(e.currentTarget);
    setSelectedLocation(null);
  };

  // const handleInputFocus = (e) => {
  //   setShowBorder(true);
  //   setAnchorEl(e.currentTarget);
  // };

  const handlePopularLocationClick = async (name, latitude, longitude) => {
    setSelectedPlace(name);
    handleClose();
    if (longitude && latitude) {
      let location = { lat: latitude, lng: longitude };
      setSelectedLocation(location);
      setSearchCity("");
      setSearchedPlaces(null);
    }
  };

  const [options, setOptions] = useState({
    adult: guest ? parseInt(guest) : 0,
    children: child ? parseInt(child) : 0,
    infants: infant ? parseInt(infant) : 0,
  });

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };

  const handleCloseOption = async (e) => {
    setOptions({
      adult: 0,
      children: 0,
      infants: 0,
    });
  };

  const findAllLocation = async (guest, child, infant) => {
    if (selectedLocation) {
      let latitude = selectedLocation.lat;
      let longitude = selectedLocation.lng;
      if (selectedLocation && latitude && longitude && selectedPlace) {
        let location = `${latitude},${longitude}`;
        router.replace(
          `/locationSearch?location=${location}&${
            from
              ? `from=${formatYearDate(from)}`
              : fromDate && `from=${fromDate}`
          }&${
            to
              ? `to=${formatYearDate(to)}`
              : toDate
              ? `to=${toDate}`
              : from && `to=${dateAdd(from, 1)}`
          }&${`placeName=${selectedPlace}`}&${guest && `guest=${guest}`}&${
            child && `child=${child}`
          }&${infant && `infant=${infant}`}`,
          undefined,
          { shallow: true }
        );
        reset();
        setFrom();
        setTo();
        handleCloseOption();
      } else {
        return;
      }
    } else if (locationPrev) {
      router.replace(
        `/locationSearch?location=${locationPrev}&${
          from ? `from=${formatYearDate(from)}` : fromDate && `from=${fromDate}`
        }&${
          to
            ? `to=${formatYearDate(to)}`
            : toDate
            ? `to=${toDate}`
            : from && `to=${dateAdd(from, 1)}`
        }&${`placeName=${placeName}`}&${guest && `guest=${guest}`}&${
          child && `child=${child}`
        }&${infant && `infant=${infant}`}`,
        undefined,
        { shallow: true }
      );
    } else {
      enqueueSnackbar("Please Select a valid Location", {
        variant: "error",
      });
    }
  };

  const onSubmit = async (e) => {
    await findAllLocation(options.adult, options.children, options.infants);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchedPlaces) {
        handlePopularLocationClick(
          searchedPlaces[0]?.name,
          searchedPlaces[0]?.lat,
          searchedPlaces[0]?.lng
        );
      }
    }
  };

  const [showBorder, setShowBorder] = useState(false);
  return (
    <Box>
      <DateRangePickerMenu
        anchorDateOpen={anchorDateOpen}
        handleDateClose={handleDateClose}
        from={from}
        to={to}
        setFrom={setFrom}
        setTo={setTo}
      />
      <SearchedLocationMenu
        searchedPlaces={searchedPlaces}
        isLoading={isLoading}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handlePopularLocationClick={handlePopularLocationClick}
        popularLocations={popularLocations}
      />
      <SelectGuestMenu
        anchorGuestOpen={anchorGuestOpen}
        handleGuestClose={handleGuestClose}
        handleOption={handleOption}
        options={options}
        handleCloseOption={handleCloseOption}
      />
      <FormProvider
        {...{
          handleSubmit,
          register,
          unregister,
          setValue,
          errors,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              //  ...flexCenter,
              position: "relative",
            }}
            className={width ? "" : classes.paperRoot}
          >
            <Paper>
              <Grid container rowSpacing={0} columnSpacing={height ? 2 : 1}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      height: height ? height : "56px",
                      width: "100%",
                      border: showBorder && "1px solid var(--primary-color)",
                    }}
                    className={classes.boxRoot}
                  >
                    <IconButton sx={{ mx: "6px" }} aria-label="menu">
                      <img src="/images/icons/location.svg" alt="" />
                    </IconButton>

                    <Box sx={{ width: "calc(100% - 10px)" }}>
                      <Typography
                        variant="h6"
                        sx={{ color: "var(--text-secondary)" }}
                      >
                        Where do you want to stay?
                      </Typography>

                      <TextField
                        fullWidth
                        type="text"
                        autoComplete="off"
                        variant="standard"
                        placeholder="Enter Location"
                        onFocus={() => setShowBorder(true)}
                        onBlur={() => setShowBorder(false)}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#1A1819",
                            padding: 0,
                            opacity: 1,
                          },
                        }}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => handleOnChange(e)}
                        value={selectedPlace && selectedPlace}
                      />
                    </Box>

                    <IconButton
                      type="button"
                      sx={{ mx: "6px" }}
                      aria-label="search"
                    >
                      <img src="/images/icons/search-normal.svg" alt="" />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={height ? 4 : 3}>
                  <Box
                    sx={{
                      height: height ? height : "56px",
                      width: "100%",
                      border:
                        anchorDateOpen && "1px solid var(--primary-color)",
                    }}
                    className={classes.boxRoot}
                    onClick={handleDateOpen}
                  >
                    <Box sx={flexBetweenCenter}>
                      <IconButton sx={{ mx: "6px" }} aria-label="menu">
                        <img src="/images/icons/calendar.svg" alt="" />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" sx={{ color: "#666870" }}>
                          Check In - Check Out
                        </Typography>
                        <TextField
                          fullWidth
                          readOnly
                          type="text"
                          autoComplete="off"
                          variant="standard"
                          placeholder="Select Date"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "#1A1819",
                              padding: 0,
                              opacity: 1,
                            },
                          }}
                          value={
                            from && to
                              ? formatDateRange(from, to)
                              : fromDate && toDate
                              ? formatDateRange(fromDate, toDate)
                              : from && !to
                              ? formatDateRange(from, dateAdd(from, 1))
                              : ""
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={height ? 2 : 3}>
                  <Box
                    sx={{
                      height: height ? height : "56px",
                      width: "100%",
                      border:
                        anchorGuestOpen && "1px solid var(--primary-color)",
                    }}
                    className={classes.boxRoot}
                    onClick={handleGuestOpen}
                  >
                    <IconButton sx={{ mx: "6px" }} aria-label="menu">
                      <img src="/images/icons/profile.svg" alt="" />
                    </IconButton>
                    <Box sx={{ width: "calc(100% - 10px)" }}>
                      <Typography variant="h6" sx={{ color: " #666870" }}>
                        Guest
                      </Typography>

                      <TextField
                        readOnly
                        autoComplete="off"
                        variant="standard"
                        placeholder="Guest"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#1A1819",
                            padding: 0,
                            cursor: "pointer",
                          },
                        }}
                        value={
                          options.adult !== 0 ||
                          options.children !== 0 ||
                          options.infants !== 0
                            ? `${
                                parseInt(options.adult) +
                                parseInt(options.children) +
                                parseInt(options.infants)
                              } guests`
                            : ""
                        }
                      />
                    </Box>
                    <IconButton sx={{ mx: "6px" }} aria-label="menu">
                      <img src="/images/icons/Vector.svg" alt="" />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={{
                      height: height ? height : "56px",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    {buttonText ? buttonText : "Search"}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
